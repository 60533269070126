// Zorro - HeaderComponent.js

import React from 'react'
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem, Jumbotron } from 'reactstrap';
import { NavLink } from 'react-router-dom'


function Header(props) {	

    const [isOpen, setIsOpen] = React.useState(false)

    const toggleNav = () =>  { 
        console.log('toggleNav, isOpen', isOpen)
        setIsOpen(!isOpen)
    }      

    return(        
        <div>
            <Navbar expand="md" className="navbar-light" style={{ backgroundColor: "#9BA6A8" }}>
                <NavbarToggler onClick={toggleNav} />
                <NavbarBrand className="mr-auto" href="/"><img src='./pigs.jpg' height="40" width="55" alt='Autokanta*' /></NavbarBrand>
                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar>
                        <NavItem>
                            <NavLink className="nav-link"  to='/start'><span className="fa fa-home fa-lg"></span>Card</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="nav-link"  to='/pay'><span className="fa fa-home fa-lg"></span>Table</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="nav-link"  to='/retrieve'><span className="fa fa-home fa-lg"></span>Table with modal</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="nav-link"  to='/modal'><span className="fa fa-home fa-lg"></span>Modal</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>                
            </Navbar>
                
            <div class="container">
                <Jumbotron style={{ backgroundColor: "#9BA6A8" }}>                    
                        <div className="row row-header">
                            <div className="col-12">
                                <h1 className="display-6">Autokanta (Zorro)</h1>
                                <p>Testataan tekniikoita.<br></br></p>
                            </div>
                        </div>                    
                </Jumbotron>
            </div>
        </div>
    )
}

export default Header


