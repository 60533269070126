import React from "react"
import { Card, CardBody, CardTitle } from 'reactstrap';

function TestForm(props) {	

	return (
		<div>
			<div className="container">
				<div className="row align-items-start">
					<div className="col-12 col-md-8 mx-auto">
						{/* <Card as="a" onClick={props.validatePayment} style={{ cursor: "pointer" }}> */}
						<Card as="a" style={{ cursor: "pointer" }}>
							<CardBody>
								<CardTitle><h4>Tulossa lähiaikoina...</h4> </CardTitle>
							</CardBody>
							{/* <CardImg width="80%" src='../assets/images/payment.png' alt="" /> */}
						</Card>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TestForm