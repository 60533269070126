import React, { useState, useEffect } from 'react';
//import { Card, CardTitle, CardImg, CardImgOverlay, CardText } from 'reactstrap';
import axios from 'axios'

//var autoStatus = 'OK';

const Brief = (props) => {

    const [auto, setAuto] = useState({})
    const [searchStatus, setSearchStatus] = useState('not started')
    //const [isOpen, setIsOpen] = useState(false);

    //const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        setSearchStatus('searching')
        axios
            .get("https://serverless-api.ypsilon.workers.dev/?regno=" + props.regNumber)
            //.get('https://185.116.6.11:8443/')
            //.get('http://185.116.6.11:3001/api/autos/' + util.cleanRegNumber(props.regNumber))
            //.get('http://localhost:3001/api/autos/' + util.cleanRegNumber(props.regNumber))
            //.get('https://rocky-basin-62339.herokuapp.com/api/autos/' + util.cleanRegNumbmer(props.regNumber))
            //.get('/api/autos/' + util.cleanRegNumbmer(props.regNumber))
            .then(response => {
                setSearchStatus('search done')
                setAuto(response.data)
            })
            .catch(function (error) {
                setSearchStatus('search failed')
            })
    }, [props.regNumber])

    console.log('Brief, props.regNumber, searchStatus sika', props.regNumber, searchStatus)

    if (Object.keys(auto).length > 0) {
        return (
            <div className="container">
                <h3>Auto löytyi blääh </h3>

            </div>
        )
    } else {
        if (searchStatus === 'searching') {
            return (
                <div className="container">
                    <h4>{searchStatus}</h4>
                    <img src='assets/images/searching.gif' alt=""></img>
                </div>
            )
        }
        return (
            <div className="container">
                <h4>Autoa {props.regnumber} ei löydy</h4>
                <img src='assets/images/think.jpg' alt=""></img>
            </div>
        )
    }
}

export default Brief;