// Zorro - RegNumberForm.js

import React, { useState } from "react"
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap'
import { Card, CardImg, CardFooter } from 'reactstrap'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'


function RegNumberForm(props) {

	const { regNumber, handleRegNumberChange, validateRegNumber, agree, handleAgreeChange } = props
	 
	const [modal, setModal] = useState(false)
    const toggleModal = () => setModal(!modal)	

	console.log('RegNumberForm ', regNumber, agree)

	return (
		<div className='container'>
			<div className="row row-content">
                <div className="col-12 col-md-3 m-1">
				{/* <div className="col-4"> */}
					<Card>
						<CardImg width="50%" src='assets/images/kuva1.png' alt="" />
					</Card>
				</div>
				<div className="col-12 col-md-5 m-1">
					<Form onSubmit={validateRegNumber}>
						<FormGroup row>
							<Label htmlFor="regnumber" md={{ size: 12 }}><strong>Kirjoita rekisterinumero</strong></Label>
							<Col md={4}>
								<Input type="text" id="regnumber" name="regnumber"
									value={regNumber}
									onChange={handleRegNumberChange} />
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col md={{ size: 12 }}>
								<FormGroup check>
									<Label check>
										<Input type="checkbox"
											name="agree"
											checked={agree}
											onChange={handleAgreeChange} /> {' '}
											Hyväksyn käyttöehdot
									</Label>
								</FormGroup>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col md={{ size: 5 }}>
								<Button color="primary" >
									Tee haku
								</Button>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col md={{ size: 5 }}>
								<Button color="muted" onClick={toggleModal} >
									Lisää hakuehtoja
								</Button>
							</Col>
						</FormGroup>						
					</Form>
					<Modal isOpen={modal} toggleModal={toggleModal} className="modal-dialog" >
						<ModalHeader toggleModal={toggleModal}>Lisää hakuehtoja</ModalHeader>
						<ModalBody>
							Tämä toiminto ei ole toistaiseksi käytössä.
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={toggleModal}>Sulje ikkuna</Button>
						</ModalFooter>
            		</Modal>					
				</div>
				<div className="col-12 col-md-3 m-1">
					<Card>
						<CardImg width="50%" src='assets/images/kuva2.png' alt="" />
						<CardFooter><h4>Lorem ipsum etc.</h4></CardFooter>
					</Card>
				</div>				
			</div>
		</div>
	);
}

export default RegNumberForm